import React from 'react'
import { graphql } from 'gatsby'

import { Seo as SEO } from '../components/Seo'
import Layout from '../components/layout/Layout'
import ImageSlider from '../components/common/ImageSlider'
import Keywords from '../components/Keywords'
const AboutBanner = React.lazy(() => import('../components/home/AboutBanner'))
const VirtualTour = React.lazy(() => import('../components/home/VirtualTour'))
const CapabilitiesSection = React.lazy(() =>
  import('../components/home/CapabilitiesSection')
)
const LatestNews = React.lazy(() => import('../components/home/LatestNews'))
const TestimonySection = React.lazy(() =>
  import('../components/home/TestimonySection')
)
const JoinUsBanner = React.lazy(() => import('../components/home/JoinUsBanner'))

const IndexPage = ({ data }) => {
  const images = data.banner.nodes
  const capabilities = data.capabilities.nodes
  const latestNewsData = data.news.nodes
  const testimonies = data.testimony.nodes
  const joinUsBanner = data.joinUsBanner.nodes

  return (
    <Layout pathname="/">
      <ImageSlider images={images} autoplay/>
      <AboutBanner/>
      <VirtualTour/>
      <CapabilitiesSection capabilities={capabilities} />
      {/* <TestimonySection testimonies={testimonies} /> */}
      <LatestNews latestNewsData={latestNewsData} />
      <JoinUsBanner joinUsBanner={joinUsBanner}/>
      <Keywords 
        id={"product-keywords"} 
        keywords={[
          "stem cell indonesia",
          "stem cell regenic",
          "cell therapy indonesia",
          "terapi sel punca indonesia",
          "secretome indonesia",
          "terapi secretome",
          "stem cell kalbe",
          "terapi regeneratif",
          "regenerative medicine indonesia",
          "exosome",
          "iPSC",
          "terapi pengobatan regeneratif"
        ]}
      />
    </Layout>
  )
}

export default IndexPage

export const Head = () => {
  return (
    <SEO
      title={`Bifarma Adiluhung | Home`}
      description={
        'Discover how Bifarma Adiluhung is revolutionizing the biopharmaceutical industry. Explore our clinically proven generics and innovative treatments for a healthier future.'
      }
      keywords={[
        "stem cell indonesia",
        "stem cell regenic",
        "cell therapy indonesia",
        "terapi sel punca indonesia",
        "secretome indonesia",
        "terapi secretome",
        "stem cell kalbe",
        "terapi regeneratif",
        "regenerative medicine indonesia",
        "exosome",
        "iPSC",
        "terapi pengobatan regeneratif"
      ]}
    />
  )
}

export const query = graphql`
  query ($language: String!, $locale: String) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    banner: allContentfulHomeBanner(sort: {fields: slideOrder, order: ASC},filter: {node_locale: {eq: "en-US"}}) {
      nodes{
        title
        image {
          gatsbyImageData
        }
      }
    }
    capabilities: allContentfulProductsServices(
      filter: {node_locale: {glob: $locale}}
    ) {
      nodes {
        image {
          gatsbyImageData(width: 400, height:300)
        }
        title
        description {
          description
        }
        slug
      }
    }
    news: allContentfulNewsEvent(
      limit: 6
      filter: { node_locale: { glob: $locale } }
      sort: { order: DESC, fields: publishDate }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(width: 400, height:250)
        }
        slug
        title
        publishDate(formatString: "DD/MM/YYYY")
      }
    }
    testimony: allContentfulTestimony(
      sort: { order: DESC, fields: createdAt }
      filter: { node_locale: { glob: $locale } }
    ) {
      nodes {
        id
        fullName
        position
        profileImage {
          gatsbyImageData
        }
        content {
          content
        }
      }
    }
    joinUsBanner: allContentfulJoinUsBanner(
      sort: { order: DESC, fields: order }
      filter: { node_locale: { glob: $locale } }
    ){
      nodes{
        image{
          gatsbyImageData
        }
        title
      }
    }
  }
`
