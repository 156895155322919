import React, { useState, useEffect } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { GatsbyImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'

const ImageSlider = ({
  autoplay = false,
  images
}) => {
    const dataLength = images.length

    const PrevArrow = ({ className, style, onClick }) => {
        return (
        <div
            className={`${className} absolute left-4 md:left-8 top-1/2 -translate-y-1/2 z-50`}
            style={{ ...style }}
        >
            <button
            className="hover:bg-dark-blue p-3 transition-colors duration-300 rounded-lg"
            onClick={onClick}
            >
            <IoIosArrowForward className="text-white rotate-180 text-xl md:text-2xl lg:text-3xl" />
            </button>
        </div>
        )
    }

    const NextArrow = ({ className, style, onClick }) => {
        return (
        <div
            className={`${className} right-4 md:right-8 top-1/2 -translate-y-1/2 z-50`}
            style={{ ...style }}
        >
            <button
            className="hover:bg-dark-blue p-3 transition-colors duration-300 rounded-lg"
            onClick={onClick}
            >
            <IoIosArrowForward className="text-white text-xl md:text-2xl lg:text-3xl" />
            </button>
        </div>
        )
    }

    const [client, setClient] = useState(false)

    useEffect(() => 
        setClient(true)
    ,[])

    const settings = {
        infinite: dataLength > 1,
        dots: false,
        speed: 1500,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoplay,
        autoplaySpeed: 5000,
        easing: 'ease-in-out',
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }

    if(!client) return <></>

    return (
        <div className='relative w-full h-auto'>
            <div className="absolute w-full h-full bg-gradient-to-t from-[rgba(0,0,0,0.3)] to-transparent z-40"></div>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div className='relative'>
                        <GatsbyImage 
                            image={image.image.gatsbyImageData} 
                            alt={image.title} 
                            className='w-full h-auto max-h-screen z-10' 
                            imgStyle={{aspectRatio: '16 / 10', objectFit: 'cover'}}
                            key={index} 
                        />
                        {/* <GatsbyImage 
                            image={image.image.gatsbyImageData} 
                            alt={image.title} 
                            className='absolute top-0 left-0 w-full h-full blur-md' 
                            imgStyle={{aspectRatio: '16 / 10', objectFit: 'cover'}}
                            key={index} 
                        /> */}
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default ImageSlider